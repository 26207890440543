import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Circle Dot', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  img: 'logoDots.png',
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'rabbit-hole.gif',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://fpetra.dev/', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'is an App',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-remixer', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-remixer', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-remixer', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'franco.petraz@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:petra@circledot.tech',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/stampDOTnetwork',
    },
    // {
    //   id: nanoid(),
    //   name: 'comments-o',
    //   url: 'https://forum.remix.app',
    // },
    {
      id: nanoid(),
      name: 'globe',
      url: 'https://hyperfy.io/circledot',
    },
  ],
};
